@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 255, 255, 255;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 15, 15, 15;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  background: rgb(var(--background-start-rgb));
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@layer components {
  .ctm-heading-2 {
    @apply leading-9 text-2xl font-bold ctm-heading;
  }
}

@layer components {
  .ctm-title {
    @apply leading-8 text-lg font-semibold text-gray-800 dark:text-gray-200;
  }
}

@layer components {
  .ctm-heading {
    @apply tracking-tight text-gray-900 dark:text-gray-100;
  }
}

@layer base {
  :root {
    --radius: 0.5rem;
  }
}

